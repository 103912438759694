.buy {

  &__paymentMethod {
    background: #ffffff;
    padding: 20px 15px;
    border-radius: 15px;
    color: #2e2e2e;
    text-align: center;
    margin: 0px 10px;
    box-sizing: border-box;
    border: 3px solid transparent;
    cursor: pointer;


    @media screen and (max-width: 768px) {
      width: 100vw;
      margin: 20px 0px;
    }

    &--selected {
      border: 3px solid #0093ff;
    }

    &__img {
      text-align: center;
      height: 40px;
      margin-bottom: 15px;
    }

    &__title {
      display: block;
    }

    &__issuers {
      height: 30px;
      padding: 0px 5px;
      margin: 10px 5px 0;
      border-radius: 8px;

    }

  }
}
