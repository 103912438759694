.newsitem {

    &__header {
        width: 100%;
        position: relative;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        margin-bottom: 20px;
        margin-top: 50px;

    }

    &__title {
        display: inline-block;
        width: calc(100% - 140px);
        font-size: 22px;
        font-weight: 600;
        color: #333;

        @media (max-width: 992px) {
            width: 100%;
        }
    }
    &__date {
        display: inline-block;
        width: 140px;
        text-align: right;

        @media (max-width: 992px) {
            width: auto;
            text-align: left;
        }
    }
}