.important-news {
  color: #333;
  padding: 5px 10px 15px;

  &__dismiss {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 999;
  }
}
