.wizardStep {
    position: absolute;
    top: 120px;
    min-height: calc(100% - 120px);
    width: 100%;
    color: #fff;
    overflow-y: scroll;
    padding-bottom: 80px;
    @media (max-width: 922px) {
        top: 80px;
        min-height: calc(100% - 80px);
    }
    h2 {
        font-size: 18px;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
        font-weight: 600;
        margin-bottom: 15px;
        @media (max-width: 922px) {
            font-size: 16px;
            margin-top: -10px;
            margin-bottom: 5px;
        }
    }
    h1 {
        font-size: 40px;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
        font-weight: 600;
        margin-bottom: 80px;
        @media (max-width: 922px) {
            margin-bottom: 30px;
            font-size: 25px;
        }
    }
    p {
        font-size: 16px;
    }
    &--withMenu {
        @media (min-width: 922px) {
            margin-top: 120px;
        }
    }
}

.wizardStep__section {
    background: 0;
    box-shadow: none;
}

.wizardStep__section__wrapper {
    position: relative;
    width: 100%;
    @media (max-width: 992px) {
        margin-top: -20px;
        >h2 {
            display: none;
        }
    }
}

.wizardLoader {
    position: relative;
    top: 200px;
}

.dataLoader {
    margin: 0;
}

.margin-bottom {
    margin-bottom: 15px;
}