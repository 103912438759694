.reservationcard {
  &--cancelled {
    p,
    h1,
    h2 {
      opacity: 0.5;
    }
  }
  width: 100%;
  padding: 20px 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
  margin-bottom: 30px;
  position: relative;
  &__loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(255, 255, 255, 0.6);
    padding-top: 100px;
    border-radius: 10px;
  }
  &__row {
    margin: 0 20px;
    padding: 20px 0;
    border-bottom: 1px solid #d9d9d9;
    &:first-child {
      padding-top: 0;
    }
    &:last-child,
    &.last {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  &__col {
    &--right {
      text-align: right;
      vertical-align: middle;
    }
    padding: 0;
    p {
      font-size: 14px;
      color: #4c4c4c;
      line-height: 20px;
      margin: 0;
    }
  }
  &__date {
    font-size: 14px;
    color: #929292;
    margin-bottom: 5px;
    display: inline-block;
    font-weight: 400;
  }
  &__title,
  h1 {
    font-size: 20px;
    color: #4c4c4c;
    margin: 0;
    &__city {
      font-size: 18px;
      color: #929292;
      margin-left: 10px;
      font-weight: 400;
    }
  }
  &__download {
    color: #ff8850;
    border: 2px solid #ff8850;
    padding: 0 15px;
    border-radius: 5px;
    line-height: 40px;
    height: 40px;
    display: inline-block;
    box-sizing: content-box;
    font-weight: 500;
    &:hover,
    &:focus {
      color: #ff8850;
      font-weight: 900;
    }
    img {
      margin-top: -3px;
      margin-right: 10px;
    }
    &--link {
      border: 0;
      width: calc(100% - 40px);
      text-align: center;
      margin: 15px 0 0 0;
      line-height: 40px;
      height: 40px;
    }

    &--disabled, &--disabled:hover {
      color: #5d5d5b !important;
      padding: 0;
      border: 0;
      font-weight: normal;
      line-height: normal;
      margin-top: 15px;

      @media (max-width: 902px) {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 35px;
      }
    }
  }
  &__cancel {
    display: inline-block;
    font-weight: 500;
    color: #ff5050 !important;
    &:hover,
    &:focus {
      color: #ff5050;
    }
    @media (max-width: 9002px) {
      width: 100%;
      border: 2px solid #ff5050;
      line-height: 50px;
      height: 50px;
      margin-top: 10px;
      text-align: center;
      border-radius: 5px;
    }
    &--own {
      color: #ff8850;
      border-color: #ff8850;
      // margin-top: 30px;
      &:hover,
      &:focus {
        color: #ff8850;
      }
    }
  }
  &__add{
    display: inline-block;
    font-weight: 500;
    color: #48cc5b !important;
    &:hover,
    &:focus {
      color: #c7c7c7;
    }
    @media (max-width: 9002px) {
      width: 100%;
      border: 2px solid #48cc5b;
      line-height: 50px;
      height: 50px;
      margin-top: 10px;
      text-align: center;
      border-radius: 5px;
    }

  }

  &--light {
    color: #838383;
  }
  &__reservation-info {
    background: #ff8850;
    padding: 10px 15px;
    border-radius: 5px;
    margin-top: 10px;
    p {
      margin: 0;
      padding: 0;
      color: #fff;
      font-size: 14px;
    }
    a {
      color: #fff;
      text-decoration: underline;
      font-size: 14px;
    }
  }

  &__cancel-player {
    background: #ffffff;
    border: 1.5px solid #ff5050;
    border-radius: 5px;
    padding: 1px 6px 2px;
    margin-left: 4px;
    margin-top: 0px;
  }


}

.player {
  &__download {
    color: #ff8850;
    strong,
    small {
      color: #ff8850 !important;
    }

    line-height: 45px;
    transition: all 0.2s ease;
    &:hover {
      color: #333;
      font-weight: 900;
      text-decoration: none;
    }
    &__icon {
      margin-right: 10px;
      display: inline-block;
    }


  }
  &--cancelled {
    text-decoration: line-through;
    color: #aaa;
  }
}


.alert {
  &--notify {
    background: #ffbc71;
    color: #fff;
    height: 45px;
    padding-top: 12px;
    margin:15px;
  }
}
