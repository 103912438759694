.reservationCourse-wrapper,
.reservationDate-wrapper {
  > h1 {
    margin-bottom: 0;
    display: inline-block;
  }
  &__switchtype {
    display: inline-block;
    margin: 10px 0 40px 15px;
    @media (max-width: 440px) {
      margin-left: 0;
      display: block;
    }
    a {
      color: #fff;
      text-decoration: underline;
      transition: all 0.3s ease;
      font-size: 20px;
      font-weight: 600;
      &:hover {
        color: #fff;
        text-decoration: underline;
        font-weight: 600;
        transition: all 0.3s ease;
        &:hover {
          color: #fff;
          opacity: 0.4;
        }
      }
    }
  }
}

.wizardStep {
  .reservationCourse-map {
    #map {
      width: 100%;
      height: 55vh;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
      border-radius: 5px;
      overflow: hidden;
    }
    &__window {
      text-align: center;
      overflow: hidden;
      &__title {
        color: #f46547;
        margin: 0 0 5px 0;
        font-size: 16px;
      }
      &__city {
        color: #333;
        margin: 0;
        font-size: 14px;
      }
      &__button {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        min-width: 100px;
        font-weight: 500;
        font-size: 14px;
        background: #f46547;
        color: #fff;
        border: none;
        border-radius: 6px;
        box-sizing: border-box;
        transition: all 0.2s ease;
        margin-top: 15px;
        width: 100%;
      }
    }
    &__buttons {
      display: none;
    }
    &__switch-view {
      color: #fff;
      text-decoration: underline;
      display: block;
      margin-bottom: 10px;
      span {
        color: #fff;
      }
      border-bottom-color: #fff;
      display: block;
      margin-bottom: 10px;
      width: 100%;
      &:hover {
        color: #fff !important;
        opacity: 0.8;
      }
    }
  }
  .reservationCourse-clubfilter {
    width: 100%;
    max-height: calc(100vh - 400px);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
    background: #fff;
    border-radius: 5px;
    position: relative;
    &__input {
      border: 0;
      color: #595959;
      padding: 15px;
      width: 100%;
      border-bottom: 1px solid #cecdcd;
      background: none;
      box-shadow: none;
      transition: all 0.3s ease;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &.active {
        box-shadow: 0 2px 8px rgba(255, 71, 0, 0.44);
        border-bottom-color: transparent;
      }
      &::placeholder {
        font-style: italic;
      }
    }
    &__filterInfo {
      position: absolute;
      top: -25px;
      right: 15px;
      color: #fff;
    }
    &__list {
      margin: 0;
      padding: 0;
      max-height: calc(100vh - 450px);
      overflow: scroll;
      li {
        color: #333;
        list-style: none;
        padding: 12px 15px;
        margin: 0;
        border-bottom: 1px solid #cecdcd;
        cursor: pointer;
        transition: all 0.1s ease;
        &:last-child {
          border-bottom: none;
        }
        span {
          transition: all 0.1s ease;
        }
        @media (min-width: 922px) {
          &:hover {
            background: rgb(241, 241, 241);
            color: #222;
            span {
              color: #ff5140;
            }
          }
        }
        &.selected {
          background: #03b96e;
          color: #fff;
          span {
            color: #fff;
          }
          border-bottom-color: #fff;
        }
        &.disabled {
          background: #f1f1f1;
          color: #999;
          cursor: not-allowed;
          span {
            color: #999;
          }
          span.disabled-message {
            color: rgb(255, 72, 0);
            display: block;
            margin-top: 5px;
          }
          // border-bottom-color: #fff;
        }
        .reservationCourse-clubfilter__name {
          font-weight: 600;
          color: #595959;
        }
        .reservationCourse-clubfilter__city {
          margin-left: 5px;
          color: #999;
          @media (max-width: 922px) {
            display: block;
            margin: 0;
          }
        }

        .reservationCourse-clubfilter__instruction {
          max-height: 0;
          overflow: hidden;
          transition: 0.3s ease max-height;

          span {
            // background-color: #ffffff;
            display: block;
            padding: 10px 0px 5px;
            // color: #ff5140;
            // border-radius: 5px;
          }
        }

        &.selected .reservationCourse-clubfilter__instruction {
          max-height: 120px;
        }
      }
    }
    @media (max-width: 922px) {
      max-height: calc(100vh - 300px);
      &__input {
        font-size: 16px;
      }
      &__list {
        max-height: calc(100vh - 360px);
      }
    }
  }
}
