.holes-hole  {
    > * {
        cursor: pointer;
    }

    &--disabled > * {
        cursor: not-allowed
    }

    &--disabled {
        * {
            color: #FCC6A9;
        }

        .holes-radio {
            border-color: #FCC6A9;
        }
    }
    margin-bottom: 25px;
}

.holes-radio {
    width:22px;
    height: 22px;
    border: 11px solid #fff;
    border-radius: 100px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.24);
    content: "f";
    display: inline-block;
    background: #333;
    vertical-align: middle;
    margin-right: 10px;
    transition: all 0.1s ease;

    &.holes-radio--active {
        border-width: 5px;
    }

}

.holes-label {
    font-size: 16px;
    line-height: 22px;
    display: inline-block;
    vertical-align: middle;

    &--disabled {
        display: block;
        font-size: 14px;
        color: #fff;
        strong {
            color: #fff;
        }
    }
}