
.cancel-reservation {
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 900;
    
    font-size: 16px;
    color: #FFFFFF;
    text-align: right;
    transition: 0.2s ease all;

    &:hover, &:focus {
        color: #fff;
        text-decoration: none;
        opacity: 0.5;
    }

    span {
        @media (max-width: 992px) {
            display: none;
        }
    }

    img {
        margin-left: 15px;
    }
}