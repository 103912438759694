.profile {
    padding: 10px 20px 30px;
    @media (max-width: 992px) {
        padding: 10px 0 30px;
    }
    &__form {
        label {
            display: block;
            font-weight: 400;
            margin-top: 15px;
            @media (max-width: 992px) {
                margin-top: 25px;
            }
            &[for] {
                display: inline-block;
                line-height: 20px;
                padding-left: 10px;
                margin-right: 15px;
            }
        }
        input[type="text"],
        input[type="email"],
        input[type="date"],
        input[type="phone"],
        input[type="number"] {
            width: 100%;
            line-height: 45px;
            height: 45px;
            padding: 0 15px;
            border-radius: 5px;
            border: 1px solid #ddd;
            &[readonly] {
                background: #eee;
            }
        }
        select {
            width: 100%;
            height: 45px;
            // border: 1px solid #333;
            background: #fff;
            padding-left: 15px;
        }
        &__fieldError {
            font-size: 13px;
            color: #ff5140;
            font-weight: 500;
        }
    }
    &__actions {
        text-align: right;
        padding-right: 15px;
        padding-top: 30px;
        button {
            background: #FF8850;
            border: none;
            height: 45px;
            line-height: 45px;
            color: #fff;
            border-radius: 5px;
            font-weight: 500;
            padding: 0 15px;
        }
    }
}