
$white: #fff;
$dark-grey: rgb(68, 68, 68);
$light-grey: #616161;
$orange: rgb(255, 104, 34);

// scaling... any units
$width: 24px;

.loader {
  position: relative;
  margin: 0 auto;
  width: $width;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &.size-16 {
    width: 16px;
  }
  &.size-24 {
    width: 24px;
  }
  &.size-36 {
    width: 36px;
  }
  &.size-48 {
    width: 48px;
  }
  &.size-100 {
    width: 100px;
  }
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: $white;


  .color-light-grey & {
    stroke: $light-grey;
  }

  .color-dark-grey & {
    stroke: $dark-grey;
  }

  .color-orange & {
    stroke: $orange;
  }


}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}


// demo-specific
body {
  background-color: $white;
}
