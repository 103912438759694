.players-overview {
    margin-top: 50px;

    @media (max-width: 992px) {
        margin-top: -10px;
    }
}
.players-overview-player {
    max-width: 0;
    min-width: 0;
    background: #FFFFFF;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.24);
    text-align: center;
    padding: 0;
    height: 190px;
    border-radius: 6px;
    margin-right: 40px;
    position: relative;
    color: #3F3F3F;
    transition: all 0.3s ease;
    overflow: hidden;
    display: inline-block;
    margin-bottom: 40px;


    @media (max-width: 992px) {
        height: 50px;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 0;
        margin-right: 0;
    }
}

.players-overview-player--name-first {
    color: #3F3F3F;
    font-size: 16px;
    margin-top: 5px;
    opacity: 0;

    @media (min-width: 992px) {
        display: inline-block;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        padding: 0 10px;
    }
}

.players-overview-player--name-last {
    color: #3F3F3F;
    font-size: 16px;
    font-weight: 400;
    opacity: 0;
    margin-left: 5px;

    @media (min-width: 992px) {
        display: inline-block;
        width: 100%;
        margin-left: 0;
        padding: 0 10px;
    }
}

.players-overview-player--info {
    margin-top: 20px;
    color: #888;
    display: inline-block;


    @media (max-width: 992px) {
        position: absolute;
        right: 50px;
        bottom: 14px;   
    }
}

.players-overview-player--remove-btn {
    position: absolute;
    bottom: 15px;
    background: 0;
    border: 0;
    padding: 0;
    width: 32px;
    left: calc( 50% - 16px);
    opacity: 0;

    @media (max-width: 922px) {
        bottom: 8px;
        left: initial;
        right: 10px;
    }
}

.players-overview-player-visible {
    max-width: 200px;
    min-width: 200px;
    padding: 15px;

    @media (max-width: 922px) {
        max-width: 100%;
        min-width: 100%;  
    }

    .players-overview-player--name-first{
        animation: name-in 0.2s ease 0.2s forwards;
    }

    .players-overview-player--name-last{
        animation: name-in 0.4s ease 0.2s forwards;
    }

    .players-overview-player--remove-btn {
        animation: remove-btn-in 0.3s cubic-bezier(0, 0, 0.38, 1.33) 0.6s forwards;
        @media (max-width: 922px) {
            animation: remove-btn-in-mobile 0.3s cubic-bezier(0, 0, 0.38, 1.33) 0.2s forwards;
        }
    }
    
}


.players-overview-player-me{
    background: rgba(0,0,0,0);
    border:3px solid #fff;
    box-sizing: border-box;
    padding: 10px 20px 0 10px;

    *{
        color: #fff !important;

    }

    @media (max-width: 992px) {
        .players-overview-player--info{
            position: absolute;
            right: 15px;
            bottom: 12px;   
        }
    }
}


// ANIMATIONS
@keyframes name-in {
    from {opacity: 0; transform: translateY(-60px);}
    to {opacity: 1; transform: translateY(0);}
}

@keyframes remove-btn-in {
    from {opacity: 0; transform: translateY(60px) rotate(-360deg);}
    to {opacity: 1; transform: translateY(0) rotate(0); }
}

@keyframes remove-btn-in-mobile {
    from {opacity: 0; transform: translateX(60px) rotate(-360deg);}
    to {opacity: 1; transform: translateX(0) rotate(0) scale(0.8); }
}


