.confirmation {
  &.active {
    height: 100vh;
    background: rgba(10, 10, 10, 0.7);
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  z-index: 9999;
  height: 0;
  text-align: center;
  background: rgba(10, 10, 10, 0);
  transition: background 0.4s ease;
}
.confirmation__container {
  text-align: left;
  width: 70vw;
  background: #fff;
  border-radius: 5px;
  display: inline-block;
  padding: 20px;
  margin: 0 15px;
  top: 30vh;
  position: relative;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.2) 0.4s transform;

  transform: translateY(-150vh);
  pointer-events: none;

  .active & {
    transform: translateY(0vh);
    pointer-events: inherit;
  }

  &--alert,
  &--alert p {
    color: #ff5050;
    display: inline-block;
    margin-top: 15px;
    font-size: 14px;
  }

  p {
    color: #333;
    font-size: 15px;
    margin-top: 10px;
  }

  &__buttons {
    width: 100%;
    text-align: right;
    &__button {
      height: 45px;
      line-height: 45px;
      color: #ff8850;
      border: 2px solid #ff8850;
      border-radius: 5px;
      box-sizing: content-box;
      padding: 0 20px;
      margin-left: 15px;
      font-weight: 500;
      background: #fff;

      &--danger {
        color: #fff;
        border: none;
        background: #ff5050;
      }
    }
  }

  @media (max-width: 992px) {
    width: 100vw;
    position: fixed;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    left: 0;
    top: auto;
    margin: 0;
    transform: translateY(350px);

    .active & {
      transform: translateY(0px);
    }

    &__buttons {
      margin-top: 30px;
      &__button {
        margin-left: 0;
        width: calc(100% - 45px);
        margin-bottom: 15px;

        &--danger {
          width: calc(100% - 40px);
          margin: 0;
        }
      }
    }
  }
}
