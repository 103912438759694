.wizardStep, .wizardStepBack {
    transition: all 1s ease;
  
    h1, h2, .reservationWizard__buttons {
        transition: all 0.8s ease;
    }
}
  
.wizardStep-enter {
    opacity: 0;
    transform: translateY(100vh);

     h1, h2 {
      transform: translateY(30vh);
      opacity: 0;

    }

    .reservationWizard__buttons {
      transform: translateY(85vh);
    }
  }
  
  .wizardStep-enter-active {
    transform: translateY(0);
    opacity: 1;

    h1, h2 {
      transform: translateY(0);
      opacity: 1;

    }

    .reservationWizard__buttons {
      transform: translateY(0);
    }
  }
  
  .wizardStep-exit {
    transform: translateY(-60vh);
    opacity: 0;

    h1, h2 {
      transform: translateY(30vh);
      opacity: 0;

    }

    .reservationWizard__buttons {
      transform: translateY(85vh);
    }
}

  .wizardStep--prev.wizardStep-enter {
    transform: translateY(100vh);
  }
  
  .wizardStep--prev.wizardStep-enter-active {
    transform: translateY(0);
  }
  
  .wizardStep--prev.wizardStep-exit {
    transform: translateY(100vh);
  }


  
.wizardStepBack-enter {
    opacity: 0;
    transform: translateY(-100vh);
  }
  
  .wizardStepBack-enter-active {
    transform: translateY(0);
    opacity: 1;
  }
  
  .wizardStepBack-exit {
    transform: translateY(80vh);
    opacity: 0;

    h3{
        transform: translateX(-100vh);
    }
}

  .wizardStepBack--prev.wizardStepBack-enter {
    transform: translateY(-100vh);
  }
  
  .wizardStepBack--prev.wizardStepBack-enter-active {
    transform: translateY(0);
  }
  
  .wizardStepBack--prev.wizardStepBack-exit {
    transform: translateY(-100vh);
  }
