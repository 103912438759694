body {
    background: #FF7B55;
    background-image: linear-gradient(90deg, #ff663a 0%, #ffa83c 100%);
    font-family: 'Ubuntu', sans-serif;
}

.root {
    background: none;
}

.nlgs-app {
    padding-top: 100px;
    background: none;
}

.main-container {
    @media screen and (max-width: 768px) {
        //display: none;
    }
}

.container {
    background-color: #fff;
    padding: 0;
    border-radius: 6px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
    position: relative;
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
        border-radius: 0;
        padding: 15px;
    }
    a,
    .a {
        color: #FF8850;
        text-decoration: none;
        &:hover {
            color: #FF8850;
            text-decoration: underline;
        }
        &--white {
            color: #fff;
            &:hover {
                color: #fff;
            }
        }

        &--underline {
            text-decoration: underline;
        }
    }
    &.reservationWizard__buttons {
        @media screen and (max-width: 768px) {
            margin-bottom: 0;
        }
    }
}

h1,
h2,
h3,
h5,
h6 {
    color: #FF8850;
}

h4 {
    color: #595959;
}

.form-ctrl {
    margin-top: 30px;
}

.input-label {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-right: 15px;
    color: #595959;
    font-weight: 500;
    input {
        margin-right: 10px;
    }
}

textarea,
select,
input,
button {
    outline: none;
}

a,
.wizardStep .container a :not(.btn) {
    color: #fff;
    &:hover {
        color: #fff;
        text-decoration: underline;
    }
}

.mb-2 {
    margin-bottom: 30px !important;
}

.mb-1 {
    margin-bottom: 15px !important;
}

.mt-1 {
    margin-top: 15px !important;
}

a.btn {
    height: 45px;
    line-height: 45px;
    box-sizing: content-box;
    border-radius: 5px;
    padding: 0 15px;
    background: #fff;
    border: none;
    color: #FF8850;
    font-weight: 500;
    transition: box-shadow 0.2s ease;
    &:hover {
        color: #FF8850;
        box-shadow: 0 3px 0 rgba(143, 40, 0, 0.3);
    }
    &--ghost {
        background: none;
        border: 1px solid #fff;
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
}

.text-right {
    text-align: right;
}

.notification {
    display: block;
    background: none;
    color: rgb(0, 119, 255);
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid rgb(0, 119, 255);
    &--warning {
        background: #ff5140;
        color: #fff;
        border: none;
    }
    &--success {
        background: #04bb78;
        color: #fff;
        border: none;
    }
}

.mt-2 {
    margin-top: 15px;
}
