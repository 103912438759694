.reservationWizard__buttons {
    text-align: right;
    padding: 0;
    background: none;
    box-shadow: none;
    bottom: 20px;
    position: fixed;
    color: #fff;
    font-size: 16px;
    padding-right: 25px;
    height: 60px;
    padding-top:5px;

    @media (max-width: 767px) {
        right: 0;
        bottom: 20px;

    }
}

.reservationWizard__buttons__button {
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    min-width: 100px;
    font-weight: 500;
    font-size: 14px;
    color: #E88E00;
    background: #fff;
    border: none;
    border-radius: 6px;
    box-sizing: border-box;
    transition: all 0.2s ease;

    a{
        width: 100%;
        height: 100%;
        display: inline-block;
        color: #E88E00;

        &:hover{
            text-decoration: none;;
        }
    }
}
.reservationWizard__buttons__button__next {
    &:hover{
        box-shadow: 0 3px 0 0 rgba(0,0,0,0.2);
    }

    &.reservationWizard__buttons__button__next--disabled, &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }
}
.reservationWizard__buttons__button__back {
    padding: 0 17px;
    color: #fff;
    background: none;
    box-shadow: inset 0 0 0 2px #fff;
    border-radius: 6px;
    margin-right: 15px;

    a {
        color: #fff;
        &:hover{
            text-decoration: none;;
        }
    }
}
