.subscriptioncard {

    &__content--historic {
        p, h1, h2{
            opacity: 0.4;
        }
    }

    &__label {
        &--upcoming {
            color: #01C860;
            font-weight: 500;
            display: inline-block;
            margin-left:10px;
        }
    }


    width: 100%;
    padding: 20px 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
    margin-bottom: 30px;
    position: relative;

    @media (min-width: 992px) {
    
        &--historic  {
            width: calc(50% - 30px);
            display: inline-block;
            vertical-align: top;
            .subscriptions__historic &{ 
                &:nth-child(odd) {
                    margin-right: 30px;
                }
            }
        }
    }


    &__loading {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background: rgba(255,255,255, 0.6);
        padding-top: 100px;
        border-radius: 10px;
    }

    &__row {
        margin: 0 20px;
        padding:20px 0;
        border-bottom: 1px solid #D9D9D9;
        

        &:first-child {
            padding-top: 0;
        }

        &:last-child, &.last {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }

    &__col {

        &--right {
            text-align: right;
            vertical-align: middle;
        }
        padding :0;

        p {
            font-size: 14px;
            color: #4C4C4C;
            line-height: 20px;
            margin: 0;
        }
        
    }

    &__date {
        font-size: 14px;
        color: #929292;
        margin-bottom: 5px;
        display: inline-block;
        font-weight: 400;
    }

    &__title, h1{ 
        font-size: 20px;
        color: #4C4C4C ;
        margin: 0;


        &__city {
            font-size: 18px;
            color: #929292;
            margin-left:10px;
            font-weight: 400;
        }
    }

    &__download {
        color: #FF8850;
        border: 2px solid #FF8850;
        padding: 0 15px;
        border-radius: 5px;
        line-height: 40px;
        height: 40px;
        display: inline-block;
        box-sizing: content-box;
        font-weight: 500;

        &:hover, &:focus {
            color: #FF8850;
        }

        img {
            margin-top: -3px;
            margin-right: 10px;
        }

        &--link {
            border:0;
            width: calc(100% - 40px);
            text-align: center;
            margin: 15px 0 0 0;
            line-height: 40px;
            height: 40px;
        }
    }

    &__cancel {
        display: inline-block;
        font-weight: 500;
        color: #FF5050;
        &:hover, &:focus {
            color: #FF5050;
        }

        @media (max-width: 992px) {
            width: 100%;
            border:1px solid #FF5050;
            line-height: 50px;
            height: 50px;
            margin-top: 10px;
            text-align: center;
            border-radius: 5px;
        }

        &--own {
            color: #FF8850;
            border-color:#FF8850;
            margin-top: 30px;
            &:hover, &:focus {
                color: #FF8850;
            }
        }

        
    }
    

    &--light {
        color: #838383;
    }
}


.player--cancelled {
    text-decoration: line-through;
    color: #aaa;
}
