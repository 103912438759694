.faq {
    padding: 15px;
    &__category-title {
        font-size: 22px;
        font-weight: 600;
        color: #333;
        margin: 30px 0 20px;
        display: inline-block;
    }
    .input-search {
        border: 1px solid #ccc;
        height: 45px;
        line-height: 45px;
        padding: 0 15px;
        border-radius: 5px;
        margin-top: 10px;
    }
}