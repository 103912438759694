header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80px;
    background: #fff;
    z-index: 999;
    box-shadow: 0px 4px 0 0 rgba(0, 0, 0, 0.15);

    &.devEnv {
        background: rgb(255, 218, 97);
        background: #ff5140;

    }


    .header-logo {
        width: 90px;
        margin-left: calc(50vw - 45px);
        margin-top: 10px;
    }

    @media (max-width: 992px) {
        height: 60px;
        .header-logo {
            width: 60px;
            margin-left: calc(50vw - 30px);
        }
    }
    
}
.hamburger {
    position: absolute;
    left: 0;
    top: 2px;
    transform: scale(0.7);
    width: 30px;

    @media (min-width: 992px) {
        display: none;
    }
}

.header-env-warn {
    width: auto;
    position: fixed;
    top: 20px;
    right: 30px;;
    height: 40px;
    line-height: 40px;
    background:#fff;
    font-size: 12px;
    text-align: center;
    z-index: 9999;
    padding: 0 15px;
    border-radius: 5px;
    color: #ff5140;

    @media (max-width: 992px) {
        top: 15px;
        right: 10px;
        padding: 0 8px;
        height: 30px;
        line-height: 30px;
        font-size: 10px;
    }
}