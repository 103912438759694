.overview {
    &-title {
        display: block;
        color: rgba(255, 255, 255, 0.75);
        font-size: 14px;
    }

    &-value {
        display: inline-block;
        color: #fff;
        font-size: 16px;
        margin-bottom: 30px;


        strong {
            font-size: 18px;
        }
    }

    @media (min-width: 992px) {
        &-title {
            font-size: 16px;
        }

        &-value {
            margin-bottom: 35px;

            font-size: 18px;
            strong {
                font-size: 20px;
            }
        }
    }
}

.wizardStep__section__wrapper a.view-reservations{
    text-decoration: underline;
}