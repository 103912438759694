.calendar {
  width: 366px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  background: #fff;
  border-radius: 5px;
  padding: 8px;
  @media (max-width: 992px) {
    width: 100%;
  }
  &__month {
    font-size: 18px;
    color: #414141;
    text-align: center;
    font-weight: bold;
    padding: 15px 0;
    display: inline-block;
    width: 80%;
    margin-left: 10%;
    border-bottom: 1px solid #ddd;
    @media (max-width: 992px) {
      padding: 5px 0;
      font-size: 16px;
      border: none;
    }
  }
  &__days {
    margin-bottom: 5px;
    span {
      width: 50px;
      line-height: 50px;
      color: #414141;
      text-align: center;
      display: inline-block;
      @media (max-width: 992px) {
        width: calc((100vw - 50px) / 7);
        max-width: 100px;
        line-height: calc((100vw - 50px) / 7);
      }
    }
  }
  &__error {
    padding: 15px 8px;
    font-size: 14px;
    color: #fff;
    background: #ff8374;
    margin-top: 20px;
    text-align: center;
    border-radius: 5px;
    opacity: 0;
    max-height: 0;
    padding: 0 8px;
    transition: all 0.2s ease;
    overflow: hidden;
    &.active {
      padding: 15px 8px;
      max-height: 80px;
      opacity: 1;
    }
  }
  &__dates {
    span {
      width: 50px;
      line-height: 50px;
      color: #c2c2c2;
      text-align: center;
      display: inline-block;
      margin-bottom: 5px;
      font-size: 14px;
      cursor: not-allowed;
      @media (max-width: 992px) {
        width: calc((100vw - 50px) / 7);
        max-width: 100px;
        line-height: calc((100vw - 50px) / 7);
      }
      &.firstOfBlock {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &.lastOfBlock {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      &:nth-child(1),
      &:nth-child(7n + 1) {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:nth-child(7n) {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      &.today {
        font-weight: 700;
      }
      &.reservable {
        background: #d6ffc1;
        color: #7c7c7c;
        cursor: pointer;
        transition: 0.1s ease all;
        @media (min-width: 992px) {
          &:hover {
            color: #fff;
            background: rgb(155, 209, 93);
          }
        }
      }
      &.warning {
        background: rgb(193, 232, 255);
        color: #7c7c7c;
        cursor: pointer;
        transition: 0.1s ease all;
        @media (min-width: 992px) {
          &:hover {
            color: #fff;
            background: rgb(64, 179, 247);
          }
        }
      }
      &.selected {
        color: #fff;
        background: rgb(155, 209, 93);
      }
      &.notReservable {
        background: #ffd5c1;
        color: #7c7c7c;
      }
    }
  }
}
