@media (min-width: 992px) {
    .menu-wrapper {
        padding: 0;
        margin-bottom: 50px;
        margin-top: 30px;
        transition: margin 0.7s cubic-bezier(0.4, 0, 0.2, 1);
        ul {
            list-style: none;
            margin: 0;
            padding-right: 60px;
            li {
                display: inline-block;
                line-height: 60px;
                a {
                    cursor: pointer;
                    margin-right: 30px;
                    font-weight: 400;
                    color: #616161;
                    height: 60px;
                    display: inline-block;
                    border-bottom: 0px solid #e88e00;
                    transition: all 0.2s ease;
                    font-size: 14px;
                    text-decoration: none;
                    &:hover,
                    &.active {
                        text-decoration: none;
                        color: #E88E00;
                    }
                    &.active {
                        border-bottom-width: 2px;
                    }
                    &.menu--logout {
                        position: absolute;
                        right: 0;
                        display: inline-block;
                        top: 0px;
                        font-weight: 500;
                    }
                }
            }
        }
        &.fullPage {
            margin-top: -110px;
        }
    }
}

@media (max-width: 992px) {
    .menu-wrapper {
        padding: 0;
        position: fixed;
        top: 64px;
        z-index: 900;
        .menu-logo {
            width: 60px;
            margin-left: calc(50vw - 30px);
            margin-top: 10px;
        }
        ul {
            height: 100vh;
            max-height: 0;
            overflow: hidden;
            transition: 0.3s ease all;
            padding: 0;
            margin: 0;
            background: #fff;
        }
        ;
        ul.active {
            max-height: 100vh;
            background: #fff;
        }
        ul li {
            list-style: none;
            width: 100vw;
            border-bottom: 1px solid #ccc;
            height: 50px;
            line-height: 50px;
            padding-left: 25px;
            a {
                color: #818181;
                font-weight: 600;
                width: 100%;
                height: 50px;
                display: inline-block;
                &.active {
                    color: #E88E00;
                }
            }
        }
    }
    .hamburger {
        position: absolute;
        left: 0;
        top: 2px;
        transform: scale(0.7);
        width: 30px;
    }
}
