.courseinfo {

    &__header {
        width: 100%;
        position: relative;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        margin-bottom: 20px;
        margin-top: 50px;

    }

    &__title {
        display: inline-block;
        font-size: 22px;
        font-weight: 600;
        color: #333;
    }
    
    &__city {
        display: block;

        @media (max-width: 992px) {
            width: auto;
            text-align: left;
        }
    }

    &__content {
        line-height: 20px;
        max-width: 100%;
        overflow: scroll;
    }

    &__table {
        width: auto;
        min-width: 100%;
        td, th {
            width: auto;
            padding: 5px;
            padding-right: 20px;
            // min-width: 200px;

            &:last-child {
                padding-right: 0;
            }

            @media (min-width: 992px) {
                &:nth-child(1){
                    width: 40%
                }
                &:nth-child(2){
                    width: 40%
                }
                &:nth-child(3){
                    width: 20%
                }
            }
        }
    }
}