.wizard-autocomplete {
    background: #FFFFFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
    height: auto;
    width: 100%;
    border-radius: 6px;
    padding: 0 15px;
    position: relative;
    margin-bottom: 20px;
}

.wizard-autocomplete-input {
    border: 0;
    height: 45px;
    padding: 15px 0;
    width: calc(100% - 60px);
    color: #616161;
    @media (max-width: 922px) {
        font-size: 16px;
    }
    &::placeholder {
        color: #aaa;
        font-size: 14px;
        font-style: italic;
    }
}

.wizard-autocomplete-error {
    position: absolute;
    display: inline-block;
    right: 35px;
    text-align: right;
    color: #FD553E;
    background: #fff;
    width: 130px;
    top: 0;
    font-size: 12px;
    height: 25px;
    line-height: 25px;
}

.wizard-autocomplete-modifier {
    position: absolute;
    display: inline-block;
    right: 15px;
    top: 10px;
}

.wizard-autocomplete-add-btn {
    background: none;
    border: none;
    padding: 0;
}

.wizard-autocomplete-recommendation-wrapper {
    position: absolute;
    width: 100%;
    background: #fff;
    margin-left: -15px;
    margin-top: -5px;
    padding-top: 5px;
    max-height: 0;
    overflow: hidden;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: all 0.2s ease;
    z-index: 999999999;
    &.active {
        max-height: 300px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24);
    }
}

.wizard-autocomplete-recommendation {
    color: #616161;
    font-weight: 600;
    min-height: 40px;
    border-top: 1px solid #ccc;
    padding: 10px 15px;
    font-size: 14px;
    &:hover {
        background: #f3f3f3;
        cursor: pointer;
    }
    &--disabled,
    &--disabled:hover {
        cursor: not-allowed;
        .wizard-autocomplete-recommendation__name {
            color: #aaa;
            text-decoration: line-through;
        }
    }
    &--nocredit,
    &--nocredit:hover {
        cursor: not-allowed;
        .wizard-autocomplete-recommendation__name {
            color: #aaa;
        }
    }
    &__message {
        font-size: 13px;
        font-weight: 400;
        margin-left: 10px;
        font-style: italic;
        color: orange;
        @media (max-width: 922px) {
            display: block;
            margin-left: 0;
        }
    }
}

.wizard-autocomplete-warning {
    background: #FFFFFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
    width: auto;
    border-radius: 6px;
    padding: 0 15px;
    margin-bottom: 0px;
    max-height: 0;
    // overflow: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    position: relative;
    &:before {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: "";
        display: block;
        border-bottom: 10px solid #fff;
        top: -9px;
        left: 15px;
        position: absolute;
    }
    &.no-arrow {
        &:before {
            display: none;
        }
    }
    &.active {
        opacity: 1;
        padding: 15px;
        max-height: 150px;
        overflow: visible;
    }
    * {
        font-size: 13px;
    }
    img {
        margin-right: 10px;
        margin-top: -3px;
    }
    strong {
        color: #FD553E;
    }
    &-message {
        height: auto;
        margin: 0 0 0 8px;
        line-height: 0;
        color: #616161;
        a {
            color: #616161;
            text-decoration: underline;
        }
        @media (max-width: 922px) {
            margin: 10px 0 0;
            line-height: 20px;
            display: block;
        }
    }
}
