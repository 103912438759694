.bonusrightscard {
    padding: 0 15px;
    &__wrapper {
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
        margin: 0 0 30px;
        padding: 20px;
        position: relative;
    }
    &--used {
        p,
        .bonusrightscard__expiredate,
        h1,
        h2 {
            opacity: 0.4;
        }
    }
    p {
        font-size: 14px;
        color: #4c4c4c;
        line-height: 20px;
        margin: 0;
    }
    &__expiredate {
        font-size: 14px;
        color: #4c4c4c;
        margin-top: 5px;
        display: inline-block;
    }
    &__title,
    h1 {
        font-size: 18px;
        color: #4c4c4c;
        line-height: 20px;
        margin: 0;
        font-weight: normal;
    }
    &--light {
        color: #838383;
    }
    &__status {
        color: #01c86d;
        font-weight: bold;
        margin-top: 20px;
        &--used {
            color: #4c4c5c;
            font-weight: normal;
        }
        &--expired {
            color: #ea2300;
            font-weight: normal;
            font-weight: bold;
        }
    }
}
